export const TERM = [
    {
        type: "header",
        content: "termHeader"
    },
    {
        type: "description",
        content: "termDescription"
    },
    {
        type: "title",
        letter: "1.",
        content: "termTitle1"
    },
    {
        type: "sub-title",
        content: "termSubTitle1"
    },
    {
        type: "title",
        letter: "2.",
        content: "termTitle2"
    },
    {
        type: "sub-title",
        content: "termSubTitle2"
    },
    {
        type: "title",
        letter: "3.",
        content: "termTitle3"
    },
    {
        type: "sub-title",
        content: "termSubTitle3"
    },
    {
        type: "title",
        letter: "4.",
        content: "termTitle4"
    },
    {
        type: "sub-title",
        content: "termSubTitle4"
    },
    {
        type: "title",
        letter: "5.",
        content: "termTitle5"
    },
    {
        type: "sub-title",
        content: "termSubTitle5"
    },
    {
        type: "title",
        letter: "6.",
        content: "termTitle6"
    },
    {
        type: "sub-title",
        content: "termSubTitle6"
    },
    {
        type: "title",
        letter: "7.",
        content: "termTitle7"
    },
    {
        type: "sub-title",
        content: "termSubTitle7"
    },
    {
        type: "title",
        letter: "8.",
        content: "termTitle8"
    },
    {
        type: "sub-title",
        content: "termSubTitle8"
    },
    {
        type: "title",
        letter: "9.",
        content: "termTitle9"
    },
    {
        type: "sub-title",
        content: "termSubTitle9"
    }
]

export const PRIVACY = [
    {
        type: "header",
        content: "privacyHeader"
    },
    {
        type: "description",
        content: "privacyDescription"
    },
    {
        type: "title",
        letter: "1.",
        content: "privacyTitle1"
    },
    {
        type: "sub-title",
        content: "privacySubTitle1"
    },
    {
        type: "title",
        letter: "2.",
        content: "privacyTitle2"
    },
    {
        type: "sub-title",
        content: "privacySubTitle2"
    },
    {
        type: "title",
        letter: "3.",
        content: "privacyTitle3"
    },
    {
        type: "sub-title",
        content: "privacySubTitle3"
    },
    {
        type: "title",
        letter: "4.",
        content: "privacyTitle4"
    },
    {
        type: "sub-title",
        content: "privacySubTitle4"
    },
    {
        type: "li",
        content: "privacyLi4a"
    },
    {
        type: "li",
        content: "privacyLi4b"
    },
    {
        type: "li",
        content: "privacyLi4c"
    },
    {
        type: "title",
        letter: "5.",
        content: "privacyTitle5"
    },
    {
        type: "sub-title",
        content: "privacySubTitle5"
    },
    {
        type: "li",
        content: "privacyLi5a"
    },
    {
        type: "li",
        content: "privacyLi5b"
    },
    {
        type: "li",
        content: "privacyLi5c"
    },
    {
        type: "li",
        content: "privacyLi5d"
    },
    {
        type: "li",
        content: "privacyLi5e"
    },
    {
        type: "title",
        letter: "6.",
        content: "privacyTitle6"
    },
    {
        type: "sub-title",
        content: "privacySubTitle6"
    },
    {
        type: "title",
        letter: "7.",
        content: "privacyTitle7"
    },
    {
        type: "sub-title",
        content: "privacySubTitle7"
    },
    {
        type: "title",
        letter: "8.",
        content: "privacyTitle8"
    },
    {
        type: "sub-title",
        content: "privacySubTitle8"
    },
    {
        type: "title",
        letter: "9.",
        content: "privacyTitle9"
    },
    {
        type: "sub-title",
        content: "privacySubTitle9"
    },
    {
        type: "title",
        letter: "10.",
        content: "privacyTitle10"
    },
    {
        type: "sub-title",
        content: "privacySubTitle10"
    },
    {
        type: "title",
        letter: "11.",
        content: "privacyTitle11"
    },
    {
        type: "sub-title",
        content: "privacySubTitle11"
    },
    {
        type: "title",
        letter: "12.",
        content: "privacyTitle12"
    },
    {
        type: "sub-title",
        content: "privacySubTitle12"
    }
]