// firebaseConfig.js
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyCgYeF50-ZaEuqUCp6g-hUcpGDnHVZt2qA",
    authDomain: "clickfaceswap-65935.firebaseapp.com",
    projectId: "clickfaceswap-65935",
    storageBucket: "clickfaceswap-65935.firebasestorage.app",
    messagingSenderId: "501199671152",
    appId: "1:501199671152:web:f13453caadc0977fd309b5",
    measurementId: "G-VMYPSD1HDE"
  };

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

export { auth, db };
