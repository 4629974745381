
import { useRef } from "react";
import { ImageButton } from "../../constant/types";
import { IMAGE_TYPE } from "../../constant/constant";

const AutorigButton = (props: ImageButton) => {
	const { children, type, btnClassName, disabled = false, onClick } = props;
	const inputRef = useRef<any>(undefined);
	const btnDisabledClassName = disabled ? " btn-disabled" : "";
	const btnAction = !disabled ? onClick : ()=>{};
	const uploadBtn = () => {
		return (
			<div className="container display-flex display-flex-col">
				<input
					type="file"
					id="myImage"
					style={{ display: "none" }}
					onChange={(event) => {
						btnAction(event);
					}}
					ref={inputRef}
				/>
				<label className={'btn ' + btnClassName + btnDisabledClassName} htmlFor="myImage">{children}</label>
			</div>
		)
	}
	const closeBtn = () => {
		return (
			<label className={`btn ` + btnClassName} onClick={() => {
				if (inputRef.current) {
					inputRef.current.value = "";
				}
				btnAction();
			}}>{children}</label>
		)
	}
	const navigateBtn= () => {
		return (
			<div className="container display-flex display-flex-col">
				<label className={'btn ' + btnClassName + btnDisabledClassName} onClick={btnAction}>{children}</label>
			</div>
		)
	}
	const resultButton = () => {
		switch (type) {
			case IMAGE_TYPE.UPLOAD:
				return uploadBtn();
			case IMAGE_TYPE.TRANSFORM:
				return uploadBtn();
			case IMAGE_TYPE.CLOSE:
				return closeBtn();
			case IMAGE_TYPE.NAVIGATE:
				return navigateBtn();
			default:
				return navigateBtn();
		}
	}
	return (resultButton())
}
export default AutorigButton;