import { useRef, useState } from 'react';
import { ControlledMenu, MenuItem, useHover, useMenuState } from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/zoom.css';
import { useTranslation } from 'react-i18next';


const HoverMenu = () => {
  const ref = useRef(null);
  const [menuState, toggle] = useMenuState({ transition: true });
  const { anchorProps, hoverProps } = useHover(menuState.state, toggle);
  const { t: translate } = useTranslation();

  return (
    <>
      <div ref={ref} {...anchorProps}>
        {translate("OtherTools")}
      </div>

      <ControlledMenu
        {...hoverProps}
        {...menuState}
        arrow
        anchorRef={ref}
        onClose={() => toggle(false)}
      >
        <MenuItem
          href="https://1clickfaceswap.click"
          target="_blank"
          rel="noopener noreferrer"
          className="menu-item"
        >{translate("Anime")}</MenuItem>
        <MenuItem
          href="https://img2manga.click"
          target="_blank"
          rel="noopener noreferrer"
          className="menu-item"
        >{translate("Manga")}</MenuItem>
        <MenuItem
          href="https://beahero.click"
          target="_blank"
          rel="noopener noreferrer"
          className="menu-item"
        >{translate("Superhero")}</MenuItem>
      </ControlledMenu>
    </>
  );
};

export default HoverMenu;