import BannerComponent from "../../components/Banner/BannerComponent";
import Footer from "../../components/Footer/Footer";
import GuideStep from "../../components/GuideStep/GuideStep";
import Header from "../../components/Header/Header";
import ImageResult from "../../components/ImageResult/ImageResult";
import ImageTransformation from "../../components/ImageTransformation/ImageTransformation";
import PortfolioComponent from "../../components/Portfolio/Portfolio";
import { ANIME_PAGE_TITLE } from "../../constant/constant";
import { useState, useEffect } from "react";

const Anime = ({sharePage = false}) => {
  const MainComponent = () => {return sharePage ? <ImageResult /> : <ImageTransformation />;}
  const [isMobile, setIsMobile] = useState(false);
    
  useEffect(() => {
    if (window.innerWidth <= 1038) {
      setIsMobile(true);
  } else {
      setIsMobile(false);
  }
}, [isMobile])
  return (
    <div className="page-container">
      <Header {...ANIME_PAGE_TITLE} />
      {/* {!sharePage && !isMobile && <BannerComponent />} */}
      <div className="main-component"><MainComponent /></div>
      <GuideStep/>
      {!sharePage && <PortfolioComponent />}
      <Footer />
    </div>
  );
}

export default Anime;
