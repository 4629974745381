import axios, { AxiosResponse } from "axios";

const getCommonHeaders = (): {
  "Content-Type": string;
} => ({
    "Content-Type": "application/json",
});

export function getHref<T>(
  url="",
  axiosOptions = {},
  headers = {},
  authed = true,
): Promise<AxiosResponse<T>> {
  if (authed) {
    headers = {
      ...headers,
      ...getCommonHeaders(),
    };
  }

  return axios.get(url, {
    headers: {
      ...headers
    },
    ...axiosOptions,
  });
}

export function postHref<T, U = {}>(
  url: string,
  data?: U,
  headers = {},
  authed = true,
): Promise<AxiosResponse<T>> {
  if (authed) {
    headers = {
      ...headers,
      ...getCommonHeaders(),
    };
  }

  return axios.post<T>(url, data, {
    headers: {
      ...headers,
    },
  });
}

export function putHref<T, U = {}>(
  url: string,
  data?: U,
  headers = {},
  authed = true,
): Promise<AxiosResponse<T>> {
  if (authed) {
    headers = {
      ...headers,
      ...getCommonHeaders(),
    };
  }

  return axios.put<T>(url, data, {
    headers: {
      ...headers,
    },
  });
}

export function deleteHref<T>(url: string): Promise<AxiosResponse<T>> {
  return axios.delete(url, {
    headers: {
      ...getCommonHeaders(),
    },
  });
}
