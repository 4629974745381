import { useTranslation } from "react-i18next";
import { ANIME_PAGE_TITLE } from "../../constant/constant";
import Header from "../Header/Header";
import { useNavigate } from "react-router-dom";

const TermsPolicy = ({ contents }) => {
	const { t: translate } = useTranslation();
	const navigate = useNavigate();
	const tableHeader = contents.find((content) => { return content.type === "header" });
	const tableDescription = contents.find((content) => { return content.type === "description" });
	const tableContent = contents.filter((content) => { return !["header", "description"].includes(content.type) });
	return (
		<div className="term-container display-flex">
			<Header {...ANIME_PAGE_TITLE}></Header>
			<div className="term-content">
				<table className="term-table">
					<thead>
						<tr>
							<td colSpan={3}><h1 className="table-head" dangerouslySetInnerHTML={{__html: translate(tableHeader.content)}}></h1></td>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td colSpan={3}><p dangerouslySetInnerHTML={{__html: translate(tableDescription.content)}}></p></td>
						</tr>
						{tableContent.map((item, index) => {
							switch (item.type) {
								case 'title':
									return (
										<tr key={`item-${index + 1}`}>
											<td className="title-letter"><h2 className="table-title">{item.letter}</h2></td>
											<td colSpan={2}><h2 className="table-title" dangerouslySetInnerHTML={{__html: translate(item.content)}}></h2></td>
										</tr>
									)
								case 'sub-title':
									return (
										<tr className="table-sub-title" key={`item-${index + 1}`}>
											<td></td>
											<td colSpan={2} dangerouslySetInnerHTML={{__html: translate(item.content)}}></td>
										</tr>
									)
								case 'li':
									return (
										<tr className="table-li" key={`item-${index + 1}`}>
											<td></td>
											<td className="li-letter">&bull;</td>
											<td dangerouslySetInnerHTML={{__html: translate(item.content)}}>
												
											</td>
										</tr>
									)
								case 'sub-li':
								default:
									return (
										<tr className="table-sub-li" key={`item-${index + 1}`}>
											<td></td>
											<td></td>
											<td>
												<ol type="a">
													{
														item.contentList?.map((subliContent, index) => {
															return (
																<li key={`sub-content-${index + 1}`} dangerouslySetInnerHTML={{__html: translate(subliContent)}}></li>
															)
														})
													}
												</ol>
											</td>
										</tr>
									)
							}
						})}
						<tr>
							<td colSpan={3}><button className="btn back-btn" onClick={()=>{navigate(-1)}}>{translate("back")}</button></td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	)
};

export default TermsPolicy;