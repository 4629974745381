import { useTranslation } from "react-i18next";
import step1 from "../../assets/images/step1.png";
import step2 from "../../assets/images/step2.png";
import step3 from "../../assets/images/step3.jpg";
import { TOP_IMG_POSITION } from "../../constant/constant";
const GuideStep = () => {
    const { t: translate } = useTranslation();
    return (
        <div className="howtofs">
            <div className="fs-title container display-flex-col">
                <p className="main-text quick-overview-title">{translate('howtofs')}</p>
            </div>
            <div className="container guide-step-container">
                <div className="step-container step1">
                    <div className="step-img container">
                        <img src={step1} alt="step1" />
                    </div>
                    <div className="step-text container">
                        <h2>
                            {translate('guideStep1')}
                        </h2>
                        <p>
                            {translate('guideStep1Des')}
                        </p>
                    </div>
                </div>
                <div className="step-container step2">
                    <div className="step-img container">
                        <img src={step2} alt="step2" />
                    </div>
                    <div className="step-text container">
                        <h2>
                            {translate('guideStep2')}
                        </h2>
                        <p>
                            {translate('guideStep2Des')}
                        </p>
                    </div>
                </div>
                <div className="step-container step3">
                    <div className="step-img container">
                        <img src={step3} alt="step3" />
                    </div>
                    <div className="step-text container">
                        <h2>
                            {translate('guideStep3')}
                        </h2>
                        <p>
                            {translate('guideStep3Des')}
                        </p>
                    </div>
                </div>
            </div>
            <div className="container">
            <button className="fs-try-btn" onClick={() => { window.scroll({ top: TOP_IMG_POSITION, left: 0, behavior: 'smooth' }) }}>{translate('tryitout')}</button>
            </div>
        </div>
    )

};

export default GuideStep;