import { useTranslation } from "react-i18next";

const ButtonListComponent = (props) => {
  const { buttonList, css } = props;
  const { t: translate } = useTranslation();
  return (
  <div className={`container btn-list-contanier ${css}`}>
    {buttonList.map((button)=>{
      return (
        <div className="btn container" onClick={button.action} key={button.id}>
          <i className={button.icon+ " btn-icon"}></i>
          <div className="btn-label">{translate(button.text)}</div>
        </div>
      )
    })}
  </div>
  )
}
export default ButtonListComponent;