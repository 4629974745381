import { createSlice } from "@reduxjs/toolkit";

// userReducer.js
const initialUserState = { 
  user: null,
  isLoggin: false,
  accessToken: ''
 };

const userSlice = createSlice({
  name: 'user',
  initialState: initialUserState,
  reducers: {
    setUser: (state, action)=>{
      state.user=action.payload;
    },
    setIsLoggedIn: (state,action)=> {
      state.isLoggin=action.payload;
    },
    setAccessToken: (state,action)=>{
      state.accessToken = action.payload;
    }
  }
})
export const {setUser, setIsLoggedIn,setAccessToken} = userSlice.actions;
export default userSlice.reducer;
