// importing material UI components

import { ScreenProps } from "../../constant/types";
import { HEADER_LOGO } from "../../constant/constant";
import { useNavigate } from "react-router-dom";
import HoverMenu from "../Menu/HoverMenu";
import FirebaseAuth from "../../firebase/FirebaseAuth";
import HamburgerMenu from "../Menu/HamburgerMenu";

function Header({ mainTitle, subTitle }: ScreenProps) {

  const navigate = useNavigate();
  return (
    <header className="landing-header">
      <div className="header">
      <HamburgerMenu />
        <img src={HEADER_LOGO} className="header-logo" alt="autorig-avt" onClick={() => { navigate("/"); window.scroll({ top: 0, left: 0, behavior: 'smooth' }) }} />
        <div className="menu">
          <HoverMenu />
        </div>
        <div className="login">
          <FirebaseAuth />
        </div>
        {/* <FacebookLogin appId="283954181115281" autoLoad={true} fields="name,email,picture" callback={responseFacebook} icon="fa-facebook" /> */}
      </div>
    </header>
  );
}
// const responseGoogle = (response) => { console.log(response); }; 
// const responseFacebook = (response) => { console.log(response); };

export default Header;
