import { CircularProgressbar } from "react-circular-progressbar";
import { useTranslation } from "react-i18next";

const CircleProgressbar = ({progress, hasError, isShow }) => {
    const {t:translate} = useTranslation();
    const className = hasError ? 'circular-progress-error' : `circular-progress-${progress===100?'success':'bar'}`;
    const progressValue = hasError ? 100 : progress;
    const progressText = hasError ? translate('tryAgain') : `${progressValue}%`;
    return (isShow && (<CircularProgressbar className={className} value={progressValue} text={progressText} />));
}
export default CircleProgressbar;