import { useTranslation } from "react-i18next";
import vnFlag from "../../assets/images/vn-flag.png";
import usFlag from "../../assets/images/us-flag.png";
import i18n from "../../i18n/i18n";

const onChangeLanguage = (language: string) => {
    i18n.changeLanguage(language);
    localStorage.setItem('language', language);
}
const Footer = () => {
    const { t: translate } = useTranslation();
    return (
        <footer className="container display-flex display-flex-col footer-container">
            <div className="term-privacy display-flex display-flex-row">
                {/* <a href="/blog" className="link">{translate("footerBlog")}</a>  */}
                <a href="/terms-of-use" className="link">{translate("footerTerm")}</a>
                <a href="/privacy-policy" className="link">{translate("footerPrivacy")}</a>
                <div className="language">
                    <button className="lang-btn" onClick={() => { onChangeLanguage('en') }}>
                        <img src={usFlag} className="flag" alt="english" />
                        <span>ENG</span>
                    </button>/<button className="lang-btn" onClick={() => { onChangeLanguage('vi') }}>
                        <img src={vnFlag} className="flag" alt="vietnam" />
                        <span>VIE</span>
                    </button>
                </div>
            </div>
            <hr />
            <div className="copy-right">
                Copyright © 2023 by <span className="author">1clickfaceswap</span>
            </div>
        </footer>
    )
};

export default Footer;