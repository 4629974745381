import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

import translationEN from './en.json';
import translationVI from './vi.json';
import axios from 'axios';

// the translations
let language = localStorage.getItem('language') || 'vi';
const resources = {
	en: {
		translation: translationEN
	},
	vi: {
		translation: translationVI
	}
};
const getGeoInfo = () => {
	axios.get('https://api.country.is/').then((response) => {
		let data = response.data;
		switch (data.country) {
			case "VN":
				language = 'vi';
				break;
			default:
				language = 'en';
				break
		}
	}).catch((error) => {
		console.log(error);
		language = 'vi';
	});
	localStorage.setItem('language', language);
	i18n
		.use(Backend)
		.use(initReactI18next)
		.init({
			resources,
			fallbackLng: language,
			debug: true,
			interpolation: {
				escapeValue: false // not needed for react as it escapes by default
			}
		});
};
if (localStorage.getItem('language')) {
	i18n
		.use(Backend)
		.use(initReactI18next)
		.init({
			resources,
			fallbackLng: localStorage.getItem('language') || "vi",
			debug: true,
			interpolation: {
				escapeValue: false // not needed for react as it escapes by default
			}
		});
} else {
	getGeoInfo();
}
export default i18n;