import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import Anime from './pages/anime/Anime';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { RouterScrollTop } from './components/RouterScrollTop/RouterScrollTop';
import { FC } from 'react';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n/i18n';
import HttpsRedirect from 'react-https-redirect';
import TermsPolicy from './components/Footer/TermsPolicy';
import { PRIVACY, TERM } from './constant/terms-policy';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { Provider } from 'react-redux';
import store from './store/store';
import "../src/styles/styles.css";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
const Pages: FC = () => {
  return (
    <BrowserRouter>
      <RouterScrollTop>
        <HttpsRedirect>
          <I18nextProvider i18n={i18n}>
            <Routes>
              <Route path="/" element={<Anime />} />
              <Route path="/1clickfaceswap/:user/:date/:img" element={<Anime sharePage={true} />} />
              <Route path="/terms-of-use" element={<TermsPolicy contents={TERM} />} />
              <Route path="/privacy-policy" element={<TermsPolicy contents={PRIVACY} />} />
              {/* <Route path="/result/:guid([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}" element={<ImageResult />} /> */}
            </Routes>
          </I18nextProvider>
        </HttpsRedirect>
      </RouterScrollTop>
    </BrowserRouter>
  );
};
root.render(
  // <GoogleOAuthProvider clientId="816875559463-36rvr3q13d5fl3a2f8abnugj2u5s5jkd.apps.googleusercontent.com">
  //   <Pages />
  // </GoogleOAuthProvider>
  <Provider store={store}>
    <Pages />
  </Provider>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
