import Resizer from "react-image-file-resizer";
import { imageHeight, imageWidth, url_parameter } from "../constant/constant";

export const resizeFile = (file: any) =>
	new Promise<any>((resolve) => {
		Resizer.imageFileResizer(
			file,
			imageWidth,
			imageHeight,
			"JPEG",
			100,
			0,
			(uri) => {
				resolve(uri);
			},
			"base64",
			imageWidth,
			imageHeight,
		);
	});

export const getUrlParameter = (responseImgUrl: any) => {
	const [_hostName, user, createdDate, img] = responseImgUrl.slice(8).split("/");
	//return `/img2anime/search?user=${user}&createdDate=${createdDate}&img=${img}`;
	return `/${url_parameter}/${user}/${createdDate}/${img}`;
}

export const convertImgSrc = (imgSrc:string) => {
	return "https://"+imgSrc;
}