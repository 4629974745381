
import Modal from "react-modal";
import CircleProgressBar from "../ProgressBar/CircleProgressBar";
import { useTranslation } from "react-i18next";
const QueueModal = ({ isModelOpen, onRequestClose, queue, progress, hasError, second }) => {
	const {t:translate} = useTranslation()
	return (
		<Modal
			isOpen={isModelOpen}
			onRequestClose={onRequestClose}
			contentLabel="Modal"
			shouldCloseOnOverlayClick={false}
			ariaHideApp={false}
			className={{
				base: "modal-base",
				afterOpen: "modal-base_after-open",
				beforeClose: "modal-base_before-close"
			}}
			overlayClassName={{
				base: "overlay-base",
				afterOpen: "overlay-base_after-open",
				beforeClose: "overlay-base_before-close"
			}}
		>
			<p dangerouslySetInnerHTML={{__html: translate('queueNote', {queue: queue})}}></p>
			{second > 0 && <p dangerouslySetInnerHTML={{__html: translate('waitingSecondNote', {second: second})}}></p>}
			{second === 0 && <p>{translate('yourTurn')}</p>}
			<CircleProgressBar progress={progress} hasError={hasError} isShow={(!!progress && progress > 0) || hasError} ></CircleProgressBar>
            <div className="adsbygoogle-center"></div>
			{/* <GoogleAds dataAdSlot={googleAdSlot.leftAd} position="center" /> */}
			{queue === 0 && <div>
				<button onClick={onRequestClose} className="btn back-btn">{translate("close")}</button>
				<p className="close-pop-up">{translate('closePopUp')}</p>
				<p className="close-pop-up try-again">{translate('tryAgainIfYouWant')}</p>
				</div>}
		</Modal>
	)
};

export default QueueModal;